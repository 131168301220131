import { render, staticRenderFns } from "./CoreNotificacaoAlarme.vue?vue&type=template&id=48c21155"
import script from "./CoreNotificacaoAlarme.vue?vue&type=script&lang=js"
export * from "./CoreNotificacaoAlarme.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports